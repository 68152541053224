<template>
  <div class="pbf" v-if="data">    

     <BrandTabDiaries
      :grower-id="data.id"      
      :owner="useAuth().getId() == data.id"
      :inurl="'/growers/' + props.id + '/diaries'"
      :intags="tags"
      />

  </div>
</template>



<script setup>


const route = useRoute();
const { $api, $ga, $helper, $tagsUtil, $head } = useNuxtApp();
var tags = ref($tagsUtil.tagsGrowerDiaries);
var grower_id = ref(route.params.id);

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  id: {
    type: Number
  }
});

// useHead(useNuxtApp().$head.getGrowerDiaries(props.data));

</script>







<style scoped>
 

</style>
